@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/Antd/antd.cleanui';
@import 'assets/styles/CleanUI/cleanui';
@import 'assets/styles/Bootstrap/bootstrap.cleanui';
@import 'assets/styles/Chartist/chartist.cleanui';
@import 'assets/styles/Nprogress/nprogress.cleanui';
@import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
@import 'assets/styles/mixins.scss';

body {
  margin: 0;

  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.ant-table {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 14px !important;
}

.ant-input-search-button {
  height: 32px;
}

.ant-input {
  padding: 5px 17px;
}

.modalScroll {
  position: relative;
  width: calc(100% - 4px);
  overflow-y: scroll;
}

.ant-form label {
  font-size: 14px !important;
}

.claims-sections {
  margin-top: 1rem;
  background-color: white;
  width: 100%;
  max-width: 120rem;
  margin-inline: auto;
  padding: 1rem;
  border-radius: 0.5rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 1px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e5e5e5;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #bec1c6;
}

.primary-blue-btn {
  background-color: $submit-blue;
  color: white;
  border-color: $submit-blue;

  &:hover {
    opacity: 0.85;
  }
}

.primary-orange-btn {
  background-color: $orange-primary-btn;
  border-color: $orange-primary-btn;
  color: white;

  &:hover {
    background-color: $orange-primary-btn;
    border-color: $orange-primary-btn;
    color: white;
    opacity: 0.8;
  }
}

.ant-pagination-simple-pager {
  input {
    border: none !important;
    pointer-events: none !important;
    padding: 3px !important;
  }
}

.ant-table-tbody > tr > td {
  height: 5px !important;
  padding: 4px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
  padding: 15px !important;
}
