@import 'assets/styles/mixins.scss';

.menu {
  :global(.ant-menu-item-divider) {
    height: 36px !important;
    margin-top: 0 !important;
    width: 1px !important;
    float: left;
  }

  :global(.ant-dropdown-menu-item) {
    padding: 0.8rem 0.8rem 0;
  }

  :global(.ant-menu-dark) {
    :global(.ant-menu-item-divider) {
      background-color: $text-darken-more !important;
    }

    .icon {
      color: $gray-darken;
    }

    .title {
      color: $gray-darken;
    }
  }

  :global(.ant-menu-light) {
    .icon {
      color: $text;
    }

    .title {
      color: $text;
    }
  }
}

.logo {
  height: 54px;
  width: 200px;
  background: $menuBg-darken;
  float: left;
}

.logoContainer {
  height: 55px;
  padding: 15px 20px 0 17px;
  text-align: center;

  img {
    height: 28px;
  }
}

.icon {
  margin-top: 8px;
  margin-right: rem(7);

  @media (max-width: $md-max-width) {
    display: none !important;
  }
}

:global(.settings__menuTop) .menu {
  display: inline-flex;
  flex-direction: row;

  .icon {
    order: -1;
  }
}

:global(.settings__menuTop .ant-menu-item) {
  display: inline-flex;
  flex-direction: row;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;

  .icon {
    order: -1;
  }
}

:global(.settings__menuTop .ant-menu-light) {
  border-bottom: none;
  box-shadow: inset 0 -1px 0 #e8e8e8;
}

.temp {
  :global(.ant-comment-inner) {
    padding: 0 !important;
  }

  .ant-dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none !important;
    border: 1px solid black;
  }
}

.scroll {
  outline: none;
  width: 470px;
  max-height: 500px;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll::-webkit-scrollbar-track {
  background: #fff;
}

.scroll::-webkit-scrollbar-thumb {
  background: #ccc;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.menuBtn {
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 3px;
}
